@import 'components/colors.theme';

.card {
  position: relative;
  display: inline-block;
  min-width: 14rem;
  max-width: 24rem;
  color: white;
  background-color: theme(alt-primary-light);
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 0.5rem 0.5rem theme(shadow);
}

.title {
  position: relative;
  display: block;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
}

.statContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  min-height: 2.5rem;
  margin: 0;
  padding: 0;
}

.stat {
  position: absolute;
  display: inline;
  left: 0;
  margin: auto;
  font-size: 1.75rem;
  font-weight: bold;
}

.icon {
  position: relative;
  color: rgb(255, 255, 255);
  display: inline;
  right: 0;
  width: 2.25rem;
  height: 2.25rem;
  margin: 0;
}

//  Navigation dots custom styling
.nav {
  display: block;
  position: relative;
  height: 2rem;
  margin: auto;
  margin-top: 0.25rem;
  overflow: hidden;
}
.nav ul {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
}
.nav li {
  background-color: white !important;
}

.skill {
  display: block;
  width: 0 !important;
  margin: auto;
  margin-bottom: 0.25rem;
  margin-right: 100%;
  opacity: 0.6;
  font-size: 1.125rem;
  white-space: nowrap;
  transition: opacity 500ms ease;
  -webkit-transition: opacity 500ms ease;
}

.invisible {
  opacity: 0 !important;
}
