@import 'components/colors.theme';

.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
  padding: 2.5rem;
  text-align: center;
  overflow: hidden;
}

.header {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  gap: 2rem;
  justify-content: center;
  margin-right: 0;
  min-width: 100%;
  height: 100%;
}

.title {
  font-size: 26px;
  font-weight: 700;
  letter-spacing: -0.6px;
  margin: 0;
  color: theme(light);
}

.subtitle {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0.25px;
  color: theme(light);
  opacity: 70%;
  max-width: 100%;
  margin: 0;
}

.image {
  width: 5rem;
  height: 5rem;
  border-radius: 8px;
  margin: 0 auto;
  color: theme(light);
}

@media screen and (min-width: 800px) {
  .content {
    text-align: left;
  }
  .header {
    min-width: 450px;
  }
  .subtitle {
    max-width: 75%;
  }
}
