@import 'components/colors.theme';

/* Social Styles */
.social {
  text-align: right;
  cursor: pointer;
}
.social ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 0;
  margin: 0;
  padding: 0;
  cursor: default;
}
.social li {
  display: block;
  font-size: 1rem;
  list-style: none;
}
.social a {
  display: block;
  color: theme(light);
  padding: 10px;
  opacity: 0.65;
  transition: opacity 0.2s ease;
  -webkit-transition: opacity 0.2s ease;
  -moz-transition: opacity 0.2s ease;
  -ms-transition: opacity 0.2s ease;
  -o-transition: opacity 0.2s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translateZ(0) scale(1, 1);
  -webkit-transform: translateZ(0) scale(1, 1);
}
.social a:hover,
.social a:active,
.social a:focus {
  color: theme(light);
  opacity: 1;
}
/* For Text Readers to read links with no content in them */
.linkContentAccessible {
  height: 1px;
  width: 1px;
  position: absolute;
  overflow: hidden;
  top: -10px;
}

// Icons
.icon {
  color: theme(light);
}
.icon-linkedin,
.icon-github {
  display: block;
  position: relative;
  width: 2rem;
  height: 2rem;
  background-size: 2rem 2rem;
  background-position: center;
  background-repeat: no-repeat;
  color: theme(light);
}
.icon-linkedin {
  background-image: url(../../images/icons/linkedIn.svg);
}
.icon-github {
  background-image: url(../../images/icons/github.svg);
}

@media all and (max-width: 850px) {
  .social ul {
    text-align: center;
  }
}
