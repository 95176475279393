body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #7f54f5;
}

p,
body,
span {
  font-family: 'Inter', sans-serif;
}

h1,
h2,
h3 {
  font-family: 'Montserrat', sans-serif;
}

code,
pre {
  font-family: 'Fira Code', monospace;
  font-size: 0.95em;
  background-color: #f5f5f5; /* Light gray background for code blocks */
  padding: 0.5em;
  border-radius: 4px;
}
