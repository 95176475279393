@import 'components/colors.theme';

.App {
  width: 100%;
  min-height: 100vh;
  background-color: theme(background-primary);
  overflow: hidden;
}

/* Containers */

#projects,
#tools,
#skills,
#gallery {
  position: relative;
}
#gallery {
  margin: auto;
}
#skills {
  margin-top: -22rem; // subtract the app-content-container gap
}

.app-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 22rem;
  position: relative;
  margin: auto;
  padding: 0;
  font-size: 16px;
  box-sizing: border-box;
}

.banner-container {
  position: relative;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  transition: height 0.5s ease-out;
}
.about-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
  margin: 0;
  padding: 0;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 4rem;
  box-sizing: border-box;

  // Fallback
  background: theme(alt-primary);
  // background: -moz-linear-gradient(to bottom, theme(alt-primary) 35%, transparent 100%);
  // background: -webkit-linear-gradient(
  //   to bottom,
  //   theme(alt-primary) 35%,
  //   transparent 100%
  // );
  // background: -o-linear-gradient(to bottom, theme(alt-primary) 35%, transparent 100%);
  // background: -linear-gradient(to bottom, theme(alt-primary) 35%, transparent 100%);
  // background: linear-gradient(to bottom, theme(alt-primary) 35%, transparent 100%);

  // Gradient and image combined
  // background: url('images/grit-overlay.gif'),
  //   -moz-linear-gradient(to bottom, theme(alt-primary) 35%, theme(background-primary) 100%);
  // background: url('images/grit-overlay.gif'),
  //   -webkit-linear-gradient(to bottom, theme(alt-primary) 35%, theme(background-primary)
  //         100%);
  // background: url('images/grit-overlay.gif'),
  //   -o-linear-gradient(to bottom, theme(alt-primary) 35%, theme(background-primary) 100%);
  // background: url('images/grit-overlay.gif'),
  //   -linear-gradient(to bottom, theme(alt-primary) 35%, theme(background-primary) 100%);
  // background: url('images/grit-overlay.gif'),
  //   linear-gradient(to bottom, theme(alt-primary) 35%, theme(background-primary) 100%);
  background: linear-gradient(
      to bottom,
      theme(alt-primary) 35%,
      theme(background-primary) 100%
    ),
    url('images/grit-overlay.gif');
  background-repeat: no-repeat, repeat;
  background-blend-mode: multiply;
  background-size: auto;
}
.about-container > span {
  flex-basis: 30%;
}

.projects-container {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
}
.project-items-container {
  position: relative;
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;
  padding-bottom: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5rem;
  align-items: start;
  justify-content: center;
  box-sizing: border-box;
}
.projects-item {
  display: block;
  margin: 0;
}

.gallery-container {
  width: 100%;
  padding: 0;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: 0;
  margin-bottom: 4rem;
  text-align: center;
  overflow: hidden;
  box-sizing: border-box;
}

/* Other */

.heading {
  position: relative;
  display: block;
  max-width: 42rem;
  height: auto;
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 1rem;
  color: theme(light);
  border-radius: 1rem;
}
.heading h2 {
  position: relative;
  display: flex;
  text-align: center;
  width: fit-content;
  margin: auto;
}
.heading p {
  font-size: 1.25rem;
  text-align: center;
  margin-top: 14px;
  margin-bottom: 14px;
  font-weight: normal;
  line-height: 2rem;
  color: theme(dull);
}
.heading-title {
  display: flex;
  position: relative;
  width: 100%;
  max-width: 650px; // for word rollup
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
  text-wrap: nowrap;
  font-size: 2rem;
  overflow: hidden;
}
.disableSelect {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.about-me-container {
  width: 100%;
  height: auto;
  padding: 1rem;
  background: linear-gradient(to bottom, theme(alt-primary) 100%, theme(alt-primary) 100%),
    url('images/grit-overlay.gif');
  background-repeat: no-repeat, repeat;
  background-blend-mode: multiply;
}

.about-me-container p {
  text-align: left;
  opacity: 0.7;
  color: white;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0.25px;
}

.glow {
  position: absolute;
  /* Keep centered when resizing */
  // left: 50%;
  left: 12.5%;
  // top: 50%;
  top: 0;
  // transform: translate(-50%, -50%);
  // width: 75rem;
  width: 75%;
  // min-height: 75rem;
  // height: 85%;
  height: 100%;
  background: radial-gradient(
      ellipse at center,
      theme(alt-primary-light) 0%,
      theme(background-primary) 70%
    ),
    url('images/grit-overlay.gif');
  background-repeat: repeat;
  background-blend-mode: multiply;
  z-index: 0;
}

.interstitial-container {
  position: relative;
  display: block;
  padding: 0;
  box-sizing: border-box;
  background-position: center;
  background: linear-gradient(to bottom, theme(alt-primary) 100%, theme(alt-primary) 100%),
    theme(banner-overlay-image), url('images/grit-overlay.gif');
  background-blend-mode: overlay, multiply, multiply;
  background-size: cover, cover, auto;
  background-repeat: repeat, no-repeat, repeat;
  background-attachment: scroll, fixed, scroll;
}

/* Resets */

html {
  width: 100%;
  margin: 0;
}

a {
  text-decoration: none;
}

// About Me section
@media all and (max-width: 850px) {
  .about-container {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
  }
  .gallery-container {
    padding-left: 0;
    padding-right: 0;
  }
  .project-items-container {
    padding-left: 0;
    padding-right: 0;
  }
  .app-content-container {
    width: 100%;
  }
}

@media all and (max-width: 450px) {
  .gallery-container {
    width: 100%;
  }
}
