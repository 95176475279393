.listContainer {
  width: auto;
  margin: auto;
  padding: 0;
  padding-left: 0.5em;
  padding-right: 0.5em;
  text-align: center;
  background-color: hsla(0, 0%, 15%, 0.85);
}
.listContainer li {
  display: inline-block;
  padding: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  opacity: 1;
}
.navContainer {
  position: absolute;
  left: 0;
  top: 0;
  visibility: visible;
  z-index: 100;
}

.swipeArea {
  height: 100%;
}

.slot {
  transition: filter 150ms ease-in-out;
  -webkit-transition: filter 150ms ease-in-out;
}

.imageLink {
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.tabContainer {
  position: relative;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  overflow: hidden;
  padding: 5px;
  margin: auto;
}
.tabContainer li {
  transition: padding 150ms ease-out;
  -webkit-transition: padding 150ms ease-out;
}

.tabContainer li.inactive {
  padding: 2px;
}
.tabContainer li.active {
  padding: 10px;
}
.tabContainer:hover {
  cursor: pointer;
}
.tabContainer:hover li.inactive {
  background-color: darkgray;
  padding: 5px;
}
.inactive {
  background-color: gray;
  border-radius: 0em;
}
.active {
  background-color: white;
  border-radius: 2em;
}

.vAlign {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
