@import 'components/colors.theme';

/* Placeholders */

.containerBanner {
  position: relative;
  height: 100vh;
  min-height: 500px;
  background: theme(banner-overlay);
  text-align: center;
}
.containerBanner h1 {
  color: theme(light);
  font-family: sans-serif;
  font-size: 4em;
  margin: auto;
  padding-top: 40vh;
}
.containerBanner p {
  color: theme(light);
  font-family: sans-serif;
  font-size: 2em;
}

.containerFooter {
  background-color: theme(alt-primary);
  height: 24px;
  padding-top: 14px;
  padding-bottom: 14px;
}

.containerProjectCard {
  display: block;
  position: relative;
  border: 8px solid theme(secondary);
  background-color: theme(primary-dark);
  width: 350px; /* See if you can remove this and modify images to resize their height/width like the text slides */
  height: 551px;
  margin: auto;
  text-align: center;
}

.containerGalleryPic {
  display: inline-block;
  position: relative;
  margin: 12px;
  overflow: hidden;
  width: 300px;
  height: 300px;
  border-color: theme(primary-dark);
  border-width: 0.5em;
  border-style: solid;
  background-color: theme(primary-light);
  box-sizing: border-box;
  animation-name: flash;
  animation-duration: 900ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  -webkit-animation-name: flash;
  -webkit-animation-duration: 900ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
}

.containerGridStack {
  display: block;
  position: relative;
  background-color: theme(primary-dark);
  margin: auto;
  width: 70%;
  height: 350px;
  text-align: center;
}

/* Preloader Anim */
.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  // bottom: 19px;
  background-color: hsl(240, 4%, 50%); // fallback
  animation-name: flash;
  animation-duration: 900ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  -webkit-animation-name: flash;
  -webkit-animation-duration: 900ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
}

.loader {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;
  border: 4px solid theme(light);
  top: 0%;
  animation: loader 2s infinite ease;
}

.loaderInner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: theme(light);
  animation: loaderInner 2s infinite ease-in;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loaderInner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

@keyframes flash {
  0% {
    background-color: hsl(240, 4%, 50%);
  }
  22% {
    background-color: hsl(240, 4%, 45%);
  }
  77% {
    background-color: hsl(240, 4%, 38%);
  }
  100% {
    background-color: hsl(240, 4%, 33%);
  }
}
@-webkit-keyframes flash {
  0% {
    background-color: hsl(240, 4%, 50%);
  }
  22% {
    background-color: hsl(240, 4%, 45%);
  }
  77% {
    background-color: hsl(240, 4%, 38%);
  }
  100% {
    background-color: hsl(240, 4%, 33%);
  }
}
