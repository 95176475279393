.content {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: start;
  justify-content: flex-start;
  max-width: 400px;
  height: 3rem;
  word-wrap: normal;
  text-wrap: nowrap;
  text-align: left;
  transition-property: transform opacity;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
}
