@import 'components/colors.theme';

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background: linear-gradient(to bottom, theme(alt-primary) 100%, theme(alt-primary) 100%),
    url('/images/grit-overlay.gif');
  background-repeat: no-repeat, repeat;
  background-blend-mode: multiply;
  background-size: auto;
  font-size: 16px;
  margin-top: 14rem;
  padding: 12px;
  padding-left: 0;
  padding-right: 0;
}
.copyright {
  padding-top: 0.25em;
  margin: auto;
  overflow: hidden;
}
.copyright p {
  margin: auto;
  color: theme(dark);
  padding: 0.5em;
  background-color: theme(light);
  border-radius: 4px;
  text-align: center;
}
footer .left {
  width: auto;
  max-width: 200px;
  text-align: left;
  margin: auto;
}
footer .right {
  width: auto;
  text-align: right;
  margin: auto;
}

@media all and (max-width: 850px) {
  footer {
    text-align: center;
  }
  .copyright {
    width: 50%;
    margin-bottom: 1em;
  }
}
