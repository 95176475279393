@import 'components/colors.theme';

.container {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #7f54f5;
  background: linear-gradient(to bottom, theme(alt-primary) 100%, theme(alt-primary) 100%),
    theme(banner-overlay-image);
  background-repeat: no-repeat, repeat;
  background-blend-mode: overlay;
  background-size: cover;
  background-position: center;
  overflow-y: auto;
  overflow-x: hidden;
}

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  padding: 2rem;
  padding-top: 4rem;
  padding-bottom: 8rem;
  margin: 0;
  max-width: 600px;
  box-sizing: border-box;
  border-radius: 0.5rem;
  // background-color: hsla(183, 78%, 59%, 0.1);
  // overflow: hidden;
}

.title {
  color: white;
  text-align: center;
}

.text {
  position: relative;
  color: white;
  text-align: center;
  line-height: 1.5rem;
}

.glow {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); // centers
  min-width: 200%;
  min-height: 100%;
  background: radial-gradient(ellipse at center, orange 0%, rgba(0, 0, 0, 0) 60%);
  background-repeat: repeat;
  background-size: cover;
  background-position: center;
  background-blend-mode: overlay;
  mix-blend-mode: overlay;
  opacity: 30%;
  pointer-events: none;
}

.inputTitleContainer {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
  margin-top: 0.25rem;
}

.inputTitle {
  font-size: 1.25rem;
  font-weight: bold;
  color: whitesmoke;
  text-align: left;
  width: 100%;
}

.inputDescr {
  color: whitesmoke;
  font-size: small;
  margin: 0;
}

.inputContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: -2rem;
  padding: 0.5rem;
  width: 100%;
  background-color: rgba(245, 245, 245, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 0.25rem;
  box-sizing: border-box;
}

.inputThing {
  font-size: 1rem;
  color: black;
  background-color: transparent;
  outline-style: none;
  border-style: none;
  padding: 0.25rem;
  margin: 0;
  width: 100%;
}
.inputContainer:hover,
.inputContainer:active {
  outline-color: white;
  outline-offset: 0.15rem;
  outline-width: 4px;
  outline-style: solid;
}

.btnContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
}

.btn {
  font-size: large;
  color: white;
  padding: 1rem;
  border-style: solid;
  border-color: white;
  border-color: rgba(245, 245, 245, 0.7);
  border-radius: 0.75rem;
  min-width: 10rem;
  background-color: rgba(245, 245, 245, 0.1);
  backdrop-filter: blur(10px);
  cursor: pointer;
}
.btn:hover,
.btn:active {
  color: black;
  background-color: white;
}

.chooserContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  position: relative;
  align-items: center;
  justify-content: space-around;
  margin-top: -2rem;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
}

.chooserBtnContainer {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: stretch;
  justify-content: center;
  padding: 0.5rem;
  background-color: rgba(245, 245, 245, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 0.25rem;
  box-sizing: border-box;
  flex: 1;
}

.radioBtn {
  position: relative;
  display: block;
  padding: 0;
  margin: 0;
  color: black;
  font-size: 1rem;
  cursor: pointer;
}

.footer {
  position: fixed;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 0.25rem;
  color: whitesmoke;
  font-size: medium;
  width: 100%;
  max-width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.25rem;
  margin: 0;
  background-color: rgba(245, 245, 245, 0.1);
  backdrop-filter: blur(10px);
  box-sizing: border-box;
}

.attribution {
  position: relative;
  display: block;
  padding: 0;
  margin: 0;
  background-color: transparent;
}

.link {
  color: white;
}
.link:hover,
.link:active {
  color: rgb(0, 255, 136);
}

@media all and (max-width: 450px) {
  .footer {
    font-size: small;
    justify-content: center;
  }
}
