.bg {
  position: relative;
  display: block;
  width: 100vw;
  height: 100vh;
}

.modelContainer {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

model-viewer {
  position: relative;
  width: 700px; // initial size
  height: 700px;
  background-color: transparent;
  // hide progress bar
  // --progress-bar-color: none;
  // --progress-bar-height: 0px;
}
